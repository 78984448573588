import React from 'react';

const LoadingScreen = () => {
  return (
    <div className="loading-page flex justify-center items-center h-screen w-full bg-white">
      <span className="spinner w-1/2 md:w-1/4">
          <img 
              src="https://res.cloudinary.com/polymath-ventures/image/upload/v1642045769/Logo_color_PV_n5bnuk.png" 
              alt="Loading" 
          />
      </span>
    </div>
  )
}

export default LoadingScreen;
