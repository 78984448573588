import Link from 'next/link';
import React from 'react';

const NotFound = () => {
  return (
    <main className="404-page bg-primary-green text-white flex flex-col gap-3 py-5 md:py-10 px-10 md:px-32">
        <h1 className="text-primary-blue text-[5rem] md:text-[5.5rem] " style={{ fontWeight: 900}}>
            404
        </h1>
        <h2 className="text-[3rem] font-[900] flex flex-col">
            <span className='text-primary-blue' style={{lineHeight: '1'}}>Whoops!</span>
            <span style={{lineHeight: '1'}}>This is a mistake</span>               
        </h2>
        <p className='text-2xl'>
            {`Also a great opportunity to learn and quickly iterate. Let's get back on track!`}
        </p>
        <div className="ctas py-10 md:py-16 flex flex-col items-center justify-center md:flex-row gap-5 md:gap-10">
            <Link href={"/"}>
                <a className="text-[1.7rem] w-full md:w-fit flex justify-center py-2 px-10 btn-filled-blue uppercase rounded-full">
                    Back Home
                </a>
            </Link>
            <span className='text-[1.5rem] md:text-[1.7rem]'>OR</span>
            <Link href={"/library"}>
                <a className="text-[1.7rem] w-full md:w-fit flex justify-center py-2 px-10 btn-ghost-blue uppercase rounded-full">
                    Visit our blog
                </a>
            </Link>
        </div>
    </main>
  )
}

export default NotFound;
