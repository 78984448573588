import { text } from "aws-sdk/clients/customerprofiles"
import { FormEvent } from "react"
import { User } from "../pages/api/user"

export interface IComponentProps<T>  {
    data: T
}

export interface ArrayItemObject<T> {
    attributes: T
}

export interface IButton {
    text: string,
    url: string,
    theme: string,
    newTab: boolean,
    color: string
}

export interface INavLink {
    text: string,
    url: string
}

export interface INavbar {
    theme: string,
    links: INavLink[],
    user?: User,
    isDisabled: boolean
}

export interface IMethodStep {
    name: string,
    icon: string,
    description: string
}

export interface ITeamMember {
    photo: string,
    name: string,
    title: string,
    bio: string
}

export interface IVenture {
    name: string,
    logo: string,
    featuredImage: string,
    description: string
}

export interface IArticle {
    thumbnail: string,
    title: string,
    summary: string,
    medium: string,
    category: string,
    publicationDate: string,
    author: string
}

export enum ArticleType {
    NORMAL,
    NORMAL_LEAN,
    FEATURED,
    REDUCED,
    FEATURED_HORIZONTAL
}


/* Types for Strapi responses */

type ContentTypeMeta = {
    createdAt: Date,
    updatedAt: Date,
    publishedAt?: Date,
    locale: string
}

export type StrapiMetaResponse = {
    pagination: {
        page: number,
        pageSize: number,
        pageCount: number,
        total: number
    }
}

export type StrapiResponse<T> = {
    data: T[],
    meta: StrapiMetaResponse
    // TODO add error attribute
}

export type GreenhouseResponse<T> = {
    jobs: T[],
    meta: any
}

export type StrapiSingleResponse<T> = {
    data: T,
    meta: StrapiMetaResponse
}

export type PageResponse = {
    id: number,
    attributes: {
        title: string,
        slug: string,
        sections: Array<StrapiExampleComponent>
    } & ContentTypeMeta
}

export type FooterResponse = {
    id: number,
    attributes: {
        pageLinks: Array<INavLink>,
        socials: Array<ExternalLinkData>,
    } & ContentTypeMeta
}

export type TeamMemberResponse = {
    id: number,
    attributes: TeamMemberData
}

export type VentureResponse = {
    id: number,
    attributes: {
        sections: Array<StrapiExampleComponent>
    } & OrganisationData
}

export type ArticleResponse = {
    id: number,
    attributes: ResourceData
}
 
export type Page = {
    id: number,
    title: string,
    slug: string[],
    header: HeaderData,
    navbarTheme: 'green' | 'red' | 'blue' | 'white' | 'transparent',
    secondaryNavbar: NavbarData,
    disableNavbar: boolean,
    sections: Array<ExampleComponentData>,
    requiresRole: any, // TODO add typing
    metaData: StrapiMetaData,
} & ContentTypeMeta

// META DATA //

export type MetaData = StrapiMetaData

export type StrapiMetaData = StrapiBaseComponent & {
    metaTitle: string,
    metaDescription: string,
    metaKeywords: string,
    shareImage: {
        data: StrapiMedia
    },
    twitterCardType: 'summary' | 'summary_large_image' | 'app' | 'player'
}

// FOOTER //

export type FooterData = StrapiFooter 

export type StrapiFooter = StrapiBaseComponent & {
    pageLinks: Array<INavLink>,
    socials: Array<ExternalLinkData>
}



/* Sections */
export type StrapiBaseComponent = {
    id: number,
    __component: string,
    sectionId: string,
    sectionTitle: string,
    categoryTitle: string
}

export type StrapiComponent = InfoGalleryData | QuotesSliderData | AirtableFormData | ResourcePicksData | DoubleSliderData | ImageGridData | ScaledStepsData | BanneredContainerData | DataFormData | AccordionData | StrapiTripleModule | StrapiExampleComponent | StrapiFounders | StrapiDataBlock | StrapiProcess | StrapiFeaturedVentures | StrapiDataList | StrapiEngagementList | StrapiDoubleBlock | StrapiVentureList | StrapiTeamMemberList | StrapiMilestoneList | StrapiResourceList | StrapiResource | ResourceGridData | ImageDataBlockData | JobBoardData | StrapiFeaturedTeamMembers | StrapiPromosBlock | StrapiFeaturedLink

export type StrapiExampleComponent = StrapiBaseComponent & {
    title: string,
    rich: string,
    number: number,
    date: string,
    bool: boolean,
    enum: 'green' | 'red' | 'blue' | 'white',
    mediaSingle?: {
        data: StrapiMedia
    }
}

export type ExampleComponentData = StrapiExampleComponent

// EXTERNAL LINK //

export type ExternalLinkData = StrapiExternalLink

export type StrapiExternalLink = StrapiBaseComponent & {
    Title: string,
    URL: string,
    description: string,
    Icon: 'Generic' | 'Twitter' | 'LinkedIn' | 'Facebook' | 'Instagram' | 'Youtube' | "Github",
    DisplayOrder: number
}

// NAVBAR //

export type NavbarData = StrapiNavbar

export type StrapiNavbar = StrapiBaseComponent & {
    theme: 'green' | 'red' | 'blue' | 'white' | 'transparent',
    links: Array<LinkData>,
    isDisabled: boolean,
    user?: User
}

// IMAGE GRID //

export type ImageGridData = StrapiImageGrid

export type StrapiImageGrid = StrapiBaseComponent & {
    title: string,
    teams: Array<TeamObjectData>
}

export type TeamObjectData = StrapiTeamObject 

export type StrapiTeamObject = StrapiBaseComponent & {
    name: string,
    image:{
        data: StrapiMedia
    }
}

// LINK //

export type LinkData = StrapiLink 

export type StrapiLink = StrapiBaseComponent & {
    text: string,
    url: string
}

// HEADER //

export type HeaderData = StrapiHeader

export type StrapiHeader = StrapiBaseComponent & {
    title: string,
    subtitle: string,
    bgImage?: {
        data: StrapiMedia
    },
    theme: 'green' | 'red' | 'blue' | 'white',
    headerType: 'auto' | 'fullscreen' | 'hidden',
    highlightContent?: boolean,
    enableAnchor?: boolean,
    callToAction?: IButton
}

// PROMOS BLOCK //

export type PromosBlockData = StrapiPromosBlock

export type StrapiPromosBlock = StrapiBaseComponent & {
    highlighted: 'right' | 'left',
    promo_1: {
        data: ArrayItemObject<PromoData>
    },
    promo_2: {
        data: ArrayItemObject<PromoData>
    },
    contain: boolean
}


// PROMO //

export type PromoData = StrapiPromo

export type StrapiPromo = StrapiBaseComponent & {
    theme: 'green' | 'red' | 'blue' | 'white' | 'green_light' | 'gray' | 'yellow',
    title: string,
    content: string,
    callToAction: IButton,
    addSubscribe: boolean
}

// SCALED STEPS //

export type ScaledStepsData = StrapiScaledSteps 

export type StrapiScaledSteps = StrapiBaseComponent & {
    title: string,
    steps: Array<SingleScaledStepData>,
    bgImageDesktop: {
        data: StrapiMedia
    },
    bgImageMobile: {
        data: StrapiMedia
    },
}

export type SingleScaledStepData = StrapiSingleScaledStep 

export type StrapiSingleScaledStep = StrapiBaseComponent & {
    title: string,
    content: string
}

// DATABLOCK //

export type DataBlockData = StrapiDataBlock

export type StrapiDataBlock = StrapiBaseComponent & {
    title: string,
    theme: string,
    bgImage?: {
        data: StrapiMedia
    },
    content?: string,
    alignment?: 'left' | 'center' | 'right',
    callToAction?: IButton,
    narrowHeight?: boolean,
    alignImage?: 'left' | 'right'
}

// DOUBLE BLOCK //

export type DoubleBlockData = StrapiDoubleBlock 

export type StrapiDoubleBlock = StrapiBaseComponent & {
    titleBlockOne: string,
    contentBlockOne: string,
    themeBlockOne: 'green' | 'red' | 'blue' | 'white',
    bgImageBlockOne?: {
        data: StrapiMedia
    },
    ctaBlockOne: IButton,
    sectionTitleBlockOne: string,
    titleBlockTwo: string,
    contentBlockTwo: string,
    themeBlockTwo: 'green' | 'red' | 'blue' | 'white',
    bgImageBlockTwo?: {
        data: StrapiMedia
    },
    ctaBlockTwo: IButton,
    sectionTitleBlockTwo: string,
    highlightedBlock: 'right' | 'left'
}

// IMAGE DATA BLOCK //

export type ImageDataBlockData = StrapiImageDataBlock

export type StrapiImageDataBlock = StrapiBaseComponent & {
    title: string,
    imageAlignment: 'left' | 'right',
    theme: 'green' | 'red' | 'blue' | 'white' | 'green_light' | 'gray',
    image: {
        data: StrapiMedia
    },
    content?: string,
    containImage?: boolean,
    media?: ExternalLinkData
}

// DOUBLE SLIDER //

export type DoubleSliderData = StrapiDoubleSlider 

export type StrapiDoubleSlider = StrapiBaseComponent & {
    order: number,
    slides: Array<DoubleBlockData>
}

// PROCESS //

export type ProcessData = StrapiProcess

export type StrapiProcess = StrapiBaseComponent & {
    title: string,
    isActive: boolean,
    steps: { 
        data: Array<ArrayItemObject<StepData>> 
    },
    callToAction: IButton
}

// METHODOLOGY STEPS //

export type StepData = StrapiStep

export type StrapiStep = StrapiBaseComponent & {
    name: string,
    description: string,
    RichTextDescription: string,
    icon: {
        data: StrapiMedia
    }
}

// TEAM MEMBER // 

export type TeamMemberData = StrapiTeamMember

export type StrapiTeamMember = StrapiBaseComponent & {
    name: string,
    seniorityLevel: 'Junior' | 'Mid' | 'Senior' | 'Director',
    organisation: OrganisationData,
    description: string,
    photo: {
        data: StrapiMedia
    },
    jobTitle: string,
    displayOrder: number,
    externalLinks: Array<ExternalLinkData>,
    joinedDate: string,
    status: 'active' | 'inactive',
    memberType: 'TeamMember' | 'Advisor' | 'Alumni',
    slug: string,
    department: {
        data: ArrayItemObject<DepartmentData>
    }
}

// DEPARTMENT //

export type DepartmentData = StrapiDepartment

export type StrapiDepartment = StrapiBaseComponent & {
    description: string,
    slug: string,
    title: string
}

// TEAM MEMBER LIST //

export type TeamMemberListData = StrapiTeamMemberList 

export type StrapiTeamMemberList = StrapiBaseComponent & {
    team_members: {
        data: Array<ArrayItemObject<TeamMemberData>>
    }
}

// FEATURE TEAM MEMBERS //

export type FeaturedTeamMembersData = StrapiFeaturedTeamMembers

export type StrapiFeaturedTeamMembers = StrapiBaseComponent & {
    title: string,
    teamMembers: {
        data: Array<ArrayItemObject<TeamMemberData>>
    }
}

// FEATURED VENTURES //

export type FeaturedVenturesData = StrapiFeaturedVentures

export type StrapiFeaturedVentures = StrapiBaseComponent & {
    title: string,
    ctaUrl: string,
    organisations: { 
        data: Array<ArrayItemObject<OrganisationData>> 
    }
}

// ORGANIZATIONS //

export type OrganisationData = StrapiOrganisation 

export type StrapiOrganisation = StrapiBaseComponent & {
    title: string,
    tagline: string,
    description: string,
    logo: {
        data: StrapiMedia
    },
    isotype: {
        data: StrapiMedia
    },
    featuredImage: {
        data: StrapiMedia
    },
    slug: string,
    status: 'active' | 'inactive',
    externalLinks: Array<ExternalLinkData>,
    industrySection: string,
    displayOrder: number
}

// VENTURE LIST //

export type VentureListData = StrapiVentureList

export type StrapiVentureList = StrapiBaseComponent & {
    organisations: {
        data: Array<ArrayItemObject<OrganisationData>>
    }
}

// DATALIST //
export type DataListData = StrapiDataList

export type StrapiDataList = StrapiBaseComponent & {
    theme: 'green' | 'blue' | 'red' | 'white',
    dataPoints: Array<DataPointData>,
    highlightValues: boolean,
    addNegativeSpace: boolean
}

// DATAPOINT //

export type DataPointData = StrapiDataPoint 

export type StrapiDataPoint = StrapiBaseComponent & {
    value: string,
    metric: string
}

// ENGAGEMENT LIST //

export type EngagementListData = StrapiEngagementList

export type StrapiEngagementList = StrapiBaseComponent & {
    theme: 'green' | 'blue' | 'red' | 'white',
    title: string,
    subtitle: string,
    actionsList: Array<EngageItemData>
}

// ENGAGE ITEM //

export type EngageItemData = StrapiEngageItem 

export type StrapiEngageItem = StrapiBaseComponent & {
    url: string,
    content: string
}

// MILESTONE LIST //

export type MilestoneListData = StrapiMilestoneList

export type StrapiMilestoneList = StrapiBaseComponent & {
    categoryTitle: string,
    theme: 'green' | 'blue' | 'red' | 'white' | 'green_light' | 'gray',
    title: string,
    milestoneList: Array<MilestoneData>
}

// MILESTONE //

export type MilestoneData = StrapiMilestone

export type StrapiMilestone = StrapiBaseComponent & {
    year: string,
    content: string,
    image: {
        data: StrapiMedia
    }
}

// FEATURED LINK //

export type FeaturedLinkData = StrapiFeaturedLink

export type StrapiFeaturedLink = StrapiBaseComponent & {
    category: string,
    title: string,
    subtitle: string,
    image: {
        data: StrapiMedia
    },
    url: string,
    ctaLink: string
}

// ACCORDION //

export type AccordionData = StrapiAccordion 

export type StrapiAccordion = StrapiBaseComponent & {
    title: string,
    items: {
        title: string,
        content: string
    }[]
}

// FOUNDERS //

export type FoundersData = StrapiFounders

export type StrapiFounders = StrapiBaseComponent & {
    title: string,
    foundersList: {
        data: Array<ArrayItemObject<TeamMemberData>>
    },
    callToAction: IButton
}

// TRIPLE MODULE //

export type TripleModuleData = StrapiTripleModule

export type StrapiTripleModule = StrapiBaseComponent & {
    modules: {
        title: string,
        content: string
    }[]
}

// RESOURCE LIST //

export type ResourceListData = StrapiResourceList

export type StrapiResourceList = StrapiBaseComponent & {
    title: string,
    category: string,
    subtitle: string,
    theme: 'green' | 'blue' | 'red' | 'white' | "yellow",
    articleFilters: {
        filterValue: string
    }[],
    resources: {
        data: Array<ArrayItemObject<ResourceData>>
    },
    button: IButton
}

// RESOURCE GRID //

export type ResourceGridData = StrapiResourceGrid

export type StrapiResourceGrid = StrapiBaseComponent & {
    colorTheme: 'green' | 'blue' | 'red' | 'white',
    resources: Array<ResourceData>,
    mostPopular: {
        data: Array<ArrayItemObject<ResourceData>>
    },
    bottomPadding?: boolean,
    pageLimit?: number,
    searchTerm?: string,
    disablePopular?: boolean
}

// RESOURCE //

export type ResourceData = StrapiResource

export type StrapiResource = StrapiBaseComponent & {
    title: string,
    slug: string,
    authors: {
        data: Array<ArrayItemObject<TeamMemberData>>
    },
    thumbnail: {
        data: StrapiMedia
    },
    headerImage: {
        data: StrapiMedia
    },
    description: string,
    consumptionTime: number,
    type: 'Audio' | 'Video' | 'Image' | 'Presentation' | 'Document' | 'ExternalArticle' | 'BlogPost' | 'Blog',
    creationDate: string,
    createdAt: string,
    publishedAt: string,
    category: {
        data: ArrayItemObject<CategoryData>
    },
    media: {
        data: Array<StrapiMedia>
    }
    metaData: MetaData,
    externalLinks: Array<ExternalLinkData>
}

// RESOURCE PICKS //

export type ResourcePicksData = StrapiResourcePicks 

export type StrapiResourcePicks = StrapiBaseComponent & {
    title: string,
    subtitle: string,
    resources: {
        data: Array<ArrayItemObject<ResourceData>>
    }
}

// DATA FORM //

export type DataFormData = StrapiDataForm 

export type StrapiDataForm = StrapiBaseComponent & {
    sectionId: string,
    formTitle: string,
    description: string,
    formFooter: string,
    actionLink: string,
    onSubmit?: (e: FormEvent<HTMLFormElement>) => void,
    formInputs: Array<FormInputData>,
    submitButton: IButton
}

// FORM INPUT //

export type FormInputData = StrapiFormInput 

export type StrapiFormInput = StrapiBaseComponent & {
    label: string,
    name: string,
    placeholder: string,
    required: boolean,
    inputType: "text" | "number" | "boolean" | "email" | "password",
    widthSpan: "FullWidth" | "HalfWidth"
}


// CATEGORY//

export type CategoryData = StrapiCategory

export type StrapiCategory = StrapiBaseComponent & {
    title: string,
    slug: string,
    children: {
        data: Array<ArrayItemObject<CategoryData>>
    },
    parent: {
        data: ArrayItemObject<CategoryData>
    },
    metaData: StrapiMetaResponse
}


export type StrapiFormat = {
    name: string,
    hash: string,
    ext: string,
    mime: string,
    width: number,
    height: number,
    size: number,
    path: string,
    url: string
}

export type StrapiMedia = {
    id: number,
    attributes: {
        name: string,
        alternativeText: string,
        caption: string,
        width: number,
        height: number,
        hash: string,
        ext: string,
        mime: string,
        size: number,
        url: string,
        previewUrl: string,
        provider: string,
        provider_metadata: any // TODO: figure out type
        createdAt: string,
        updatedAt: string
        formats: {
            [key: string]: StrapiFormat
        }
    }
}


export type JobBoardData = {
    id: number,
    __component: string,
    embedLink: string,
    jobs: Array<GreenhouseJob>
}

// GREENHOUSE JOB //

export type GreenhouseJob = {
    id: number,
    internal_job_id: number,
    absolute_url: string,
    location: {
        name: string,
    },
    title: string,
    content: string,
    departments: Array<GreenhouseDepartment>,
    offices: Array<GreenhouseOffice>,
    metadata?: any,
    error?: string,
    status?: number
}

// GREENHOUSE DEPARTMENT //

export type GreenhouseDepartment = {
    id: number,
    name: string,
    child_ids: Array<number>,
    parent_id: number
}

// GREENHOUSE OFFICE //

export type GreenhouseOffice = {
    id: number,
    name: string,
    location: string,
    child_ids: Array<number>,
    parent_id: number
}

// BANNERED CONTAINER //

export type BanneredContainerData = StrapiBanneredContainer

export type StrapiBanneredContainer = StrapiBaseComponent & {
    featuredImage: {
        data: StrapiMedia
    },
    childElement: DataFormData
}


// DOWNLOADABLE //

export type FileData = {
    restrict_to_email: string,
    generated_download_link: string,
    id: string,
    name: string,
    category: string,
    time_period: string,
    created_at: string
    available_to_all: boolean
}

// DOWNLOADABLES SECTION //

export type FilesSectionData = {
    title: string,
    files: FileData[]
}

// AIRTABLE FROM //

export type AirtableFormData = StrapiAirtableForm 

export type StrapiAirtableForm = StrapiBaseComponent & {
    scriptSrc: string,
    iframeSrc: string
}

// QUOTES SLIDER //

export type QuotesSliderData = StrapiQuotesSlider 

export type StrapiQuotesSlider = StrapiBaseComponent & {
    title: string,
    subtitle: string,
    quotes: Array<QuoteData>
}

// QUOTE //

export type QuoteData = StrapiQuote 

export type StrapiQuote = StrapiBaseComponent & {
    quote: string,
    authorName: string,
    authorTitle: string
}

// INFO GALLERY//

export type InfoGalleryData = StrapiInfoGallery 

export type StrapiInfoGallery = StrapiBaseComponent & {
    title: string,
    links: Array<InfoGalleryCardData>
}

// INFO GALLERY CARD //

export type InfoGalleryCardData = StrapiInfoGalleryCard

export type StrapiInfoGalleryCard = StrapiBaseComponent & {
    title: string,
    content: string,
    button: IButton
}

