import ArticleModule from "./ArticleModule"
import { ArrayItemObject, IComponentProps, ResourceData, ResourceGridData, ResourceListData } from '../../utils/types'
import { useState } from "react"
import { IoIosArrowDown } from "react-icons/io"
import Pagination from "../Pagination"


const FeaturedArticles = ({ data }: IComponentProps<ResourceGridData>) => {
  const [search, setSearch] = useState<string>(data.searchTerm || '')
  const [mediaFilter, setMediaFilter] = useState<string>('')
  const [sortCriteria, setSortCriteria] = useState<string>('latest')
  const [showDropdown, setShowDropdown] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState(1)
  
  let PageSize = data.pageLimit || 3

  const singleMediaTypes = data.resources
    .map(item => item.type)
    .filter((value, index, array) => array.indexOf(value) === index)

  function dateSort(a: ArrayItemObject<ResourceData>, b: ArrayItemObject<ResourceData>) {
    const dateA = a.attributes.creationDate// || a.publishedAt || a.createdAt
    const dateB = b.attributes.creationDate// || b.publishedAt || b.createdAt
    if(dateA == dateB){
      return 0
    }
    return dateA < dateB ? 1 : -1
  }

  /*THE FOLLOWING IS THE ABSOLUTE UGLIEST AND MESSIEST WAY TO FILTER THE DATA
  DO NOT TOUCH OR CHANGE THE ORDER OF ANY OPERATIONS UNTIL MY SOUL FEELS READY TO 
  ORGANIZE THIS HORROR, thanks
  */

  const getLatests = () => {
    return filterTypes(data.resources)
      .filter(x => x.creationDate)
      .map(item => item && { attributes: item })
      .sort(dateSort)
  }

  const getMostPopular = () => {
    const populars = data.mostPopular.data.length ? 
      data.mostPopular.data.map(item => item.attributes)
      : 
      data.resources
      
    return filterTypes(populars)
      .map(item => item && { attributes: item })
      .sort(dateSort)
  }

  const filterTypes = (list: Array<ResourceData>) => {
    //Filter by type of media
    if (mediaFilter === '') {
      return list
    }
    return list.filter(item => item.type === mediaFilter)
  }

  let latest = getLatests()

  const filterResults = () => {
    //This function will sort between different results depending on the sort and search criteria.
    const results = search === '' ?
      (sortCriteria === 'latest' ? latest : getMostPopular())
      :
      (filterTypes(data.resources)
      .filter(item => item.title.toLowerCase().includes(search.toLowerCase()))
        .map(item => item && { attributes: item }))

    return results
  }

  const currentTableData = () => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return filterResults().slice(firstPageIndex, lastPageIndex);
  }

  return (
    <div className={`featured-articles flex flex-col gap-5 md:gap-10 px-0 md:px-20 pt-10 md:pt-16 ${data.bottomPadding ? 'pb-10' : 'pb-0'}`}>
      <div className="flex justify-between gap-3 md:gap-5 px-5 md:px-0 flex-wrap">
        {!data.disablePopular && <div className="team-select flex justify-center items-center relative w-full md:w-1/3 lg:w-1/4">
          <span
            className={`flex flex-row justify-between items-center cursor-pointer z-[999] btn-rounded btn-filled-gray py-2 px-4 w-full ${showDropdown && 'border-solid border-[1px] border-gray-400'}`}
            onClick={() => setShowDropdown(!showDropdown)}
          >
            {sortCriteria === 'latest' ? 'Latest' : 'Most Popular'} <IoIosArrowDown className={`transition-all ${showDropdown && 'rotate-180'}`} />
          </span>
          <div className={`bg-[#E5E5E5] shadow-lg border-solid border-[1px] border-gray-400 dropdown w-full flex flex-col z-[998] absolute top-1/2 pt-6 ${showDropdown ? 'block' : 'hidden'}`}>
            <button
              className={`btn-filled-gray py-2 px-4 text-left`}
              onClick={() => {
                setSortCriteria('popular')
                setShowDropdown(false)
              }}
            >
              Most Popular
            </button>
            <button
              className={`btn-filled-gray py-2 px-4 text-left`}
              onClick={() => {
                setSortCriteria('latest')
                setShowDropdown(false)
              }}
            >
              Latest
            </button>
          </div>
        </div>}
        <div className="media-filter flex w-full md:w-auto justify-between md:justify-start gap-3">
          <button 
            className={`px-5 py-2 btn-rounded ${mediaFilter === '' ? 'btn-filled-blue' : 'btn-filled-gray'}`}
            onClick={() => setMediaFilter('')}
          >
            All
          </button>
          {singleMediaTypes.map((item, index) => (
            <button 
              key={index}
              className={`px-5 py-2 btn-rounded ${mediaFilter === item ? 'btn-filled-blue' : 'btn-filled-gray'}`}
              onClick={() => setMediaFilter(item)}
            >
              {item}
            </button>
          ))}
        </div>
        <input
          type="text"
          placeholder="Buscar"
          className="py-2 px-6 rounded-full bg-[#E5E5E5] outline-none border-none w-full md:w-1/3 lg:w-1/4"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      {currentTableData().length < 1 ?
        <div className="w-full p-10 flex justify-center align-center">
          No results Found
        </div>
        :
        <div className="px-5 md:px-0">
          <ArticleModule
            noMargin={true}
            data={{
              resources: {
                data: currentTableData()
              }} as ResourceListData}
          />
        </div>
      }
      <Pagination
        className="pagination-bar flex justify-center gap-3"
        currentPage={currentPage}
        totalCount={filterResults().length}
        pageSize={PageSize}
        onPageChange={page => {
          setCurrentPage(page)
          let mainContent = document.getElementById('main-content')
          mainContent && mainContent.scrollIntoView({ behavior: 'smooth' })
        }}
      />
    </div>
  )
}

export default FeaturedArticles