import Image from "next/image"
import Link from "next/link"
import dayjs from 'dayjs'
import { ArticleType, ResourceData } from "../../utils/types"

interface IProps {
  data: ResourceData,
  type: ArticleType,
  leanTitle?: boolean
}

const ImageEndpoint = process.env.NEXT_PUBLIC_UPLOADS_URL

const ArticleCard = ({ data, type, leanTitle }: IProps) => {
  return (
    <Link href={`/library/articles/${data.slug}`} passHref>
      <a className={`article-card flex flex-col gap-1 ${type !== ArticleType.NORMAL_LEAN ? 'hover:md:scale-[1.02]' : ''} transition-all ease-in-out cursor-pointer w-full ${type === ArticleType.FEATURED ? "article-card-featured" : type === ArticleType.FEATURED_HORIZONTAL ? 'article-card-horizontal' : ''}`}>
        <div className={`card-header flex ${type === ArticleType.REDUCED ? 'flex-row-reverse md:flex-row' : type === ArticleType.FEATURED_HORIZONTAL ? 'flex-col md:flex-row-reverse' : 'flex-col'} ${type === ArticleType.NORMAL_LEAN ? '' : 'gap-5'}`}>
          <div className={`${type === ArticleType.REDUCED ? 'w-[45%] flex h-fit' : type === ArticleType.FEATURED_HORIZONTAL ? 'w-full md:w-[50%] flex h-fit' : ''}`}>
            <div className={`relative ${type === ArticleType.REDUCED || type === ArticleType.FEATURED_HORIZONTAL  ?  'w-full aspect-[1.8] overflow-hidden' : type === ArticleType.NORMAL_LEAN ? 'w-full aspect-[2] overflow-hidden' : 'card-image '}`}>
              <Image
                src={`${ImageEndpoint}${data.thumbnail.data ? data.thumbnail.data.attributes.url : '/uploads/Diseno_sin_titulo_10_406e6211a6.png?updated_at=2022-01-19T06:28:50.803Z'}`}
                alt="article thumbnail"
                layout="fill"
                objectFit="cover"
              />
            </div>
          </div>
          <div className={`${type === ArticleType.FEATURED_HORIZONTAL ? 'flex flex-col justify-center lg:gap-5' : ''} ${type === ArticleType.REDUCED ? ' w-[55%]' : type === ArticleType.FEATURED_HORIZONTAL ? 'w-full md:w-[50%]' : ''}`}>
            <h3 className={`${type === ArticleType.FEATURED || type === ArticleType.FEATURED_HORIZONTAL ? "text-[1.4rem] md:text-[1.8rem] lg:text-[2rem] font-bold" : type === ArticleType.REDUCED ? `${leanTitle ? 'text-[1.2rem] md:text-[1.4rem] ' : 'text-[1.2rem] md:text-[1.4rem] lg:text-[1.7rem]'} font-bold`  : "text-[1.6rem] md:text-[1.5rem] font-bold"}`}>{data.title}</h3>
            <div className="card-info flex flex-col gap-5 text-gray-800">
              {(type === ArticleType.FEATURED || type === ArticleType.FEATURED_HORIZONTAL) && data.metaData && <p className="article-summary">{data.metaData.metaDescription}</p>}
            </div>
            {(type !== ArticleType.REDUCED) && <div className="card-footer">
              <span className="uppercase text-[0.8rem] font-medium text-gray-500">
                {data.creationDate ? dayjs(data.creationDate, 'YYYY-MM-DD').format('MMMM DD, YYYY') + ' | ' : null}{data.authors.data.map((item, index) => index !== (data.authors.data.length - 1) ?  item.attributes.name + ', ' : item.attributes.name)}
              </span>
            </div>}
          </div>
        </div>
      </a>
    </Link>
  )
}

export default ArticleCard
