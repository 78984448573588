import { IComponentProps, ResourceData, ResourceListData } from "../../utils/types"
import { ArticleType } from "../../utils/types"
import ArticleCard from "../cards/ArticleCard"
import ThemeMap from "../../styles/themes"
import Link from "next/link"

interface IProps {
  data: ResourceListData,
  limit?: number,
  noMargin?: boolean
}

const ArticleModule = ({ data, limit, noMargin }: IProps) => {
  const currentTheme = ThemeMap[data.theme || "yellow"]
  function dateSort(a: ResourceData, b: ResourceData) {
    const dateA = a.creationDate// || a.publishedAt || a.createdAt
    const dateB = b.creationDate// || b.publishedAt || b.createdAt
    if(dateA == dateB){
      return 0
    }
    return dateA < dateB ? 1 : -1
  }
  
  const getLatests = () => {
    return data.resources.data.filter(x => x.attributes.creationDate).map(item => item.attributes).sort(dateSort)
  }
  
  let latest = getLatests()

  let allArticles = [...latest, ...data.resources.data.filter(x => x.attributes.creationDate === null).map(y => y.attributes)]

  allArticles = data.articleFilters?.length ? allArticles.filter(item => data.articleFilters.some(filter => filter.filterValue === item.type)) : allArticles

  limit ? (allArticles = allArticles.slice(0, limit)) : (allArticles)

  return (
    <div className={`featured-articles-picks flex flex-col gap-5 md:gap-9 ${noMargin === true ? 'p-0' : 'p-5 py-12 md:p-20 md:py-15 lg:py-20'}`}>
      {/* {data.category && 
        <div className={`${currentTheme.primary}  ${currentTheme.textPrimary} ${currentTheme.primary} text-xl md:text-[1.5rem] text-left font-bold py-1 px-3`}>
          <h3>{data.category}</h3>
        </div>
      } */}
      {(data.title || data.subtitle) &&
        <div className="flex flex-col gap-3">
          {data.title &&
            <h4 className="text-3xl md:text-[2.7rem] font-bold text-primary-blue">{data.title}</h4>
          }
          {data.subtitle &&
            <h5 className="text-lg md:text-[1.4rem] text-primary-blue">{data.subtitle}</h5>
          }
        </div>
      }
      <div className="card-container card-container-articles">
        {allArticles.map((item, index) => (
          <ArticleCard type={ArticleType.NORMAL} key={index} data={item} />
        ))}
      </div>
      {data.button && 
        <Link href={data.button.url}>
            <a className={`btn text-xl self-center select-none btn-rounded btn-lg btn-${data.button.theme}-${data.button.color}`}>
                {data.button.text}
            </a>
        </Link>
      }
    </div>
  )
}

export default ArticleModule
